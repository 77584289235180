import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { DateTimePicker } from '@material-ui/pickers'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import Checkbox from '@material-ui/core/Checkbox'
import moment, { Moment } from 'moment'
import { InputLabel, Chip, useTheme, Typography } from '@material-ui/core'
import { MultiSelect } from 'react-multi-select-component'
import InputAdornment from '@material-ui/core/InputAdornment'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

import { Dialog, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS, STRINGS } from 'consts'
import { Tag } from 'features/Main/Tags/types'

import { createNotification, fetchPushUserCount } from '../api'
import { CreateNotificationPayload, Notification, CreateNotificationResponse, DataParams, DataResponse } from '../types'
import { useStyles } from './styles'
import VisitsFilter from './filters/VisitsFilter'
import DateRangeFilter from './filters/DateRangeFilter'
import RewardFilter from './filters/RewardFilter'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 50,
      message: 'No puede superar los 50 caracteres',
    },
  ],
  body: [
    {
      validate: (body: string) => body.length <= 192,
      message: 'No puede superar los 192 caracteres',
    },
  ],
  visitsFilter: [
    {
      validate: (visitsFilter: string | null) =>
        visitsFilter == null ? true : parseInt(visitsFilter) <= 1000,
      message: 'Máximo permitido: 1000',
    },
  ],
}

interface DuplicateNotificationDialogProps {
  notification: Notification
  handleClose: () => void
  onDone: (newNotification: Notification) => void
  tags: Tag[]
  calcUsersByTags: (tagsSelected: Tag[], allTagsAreRequired?: boolean) => Promise<number>
}

const DuplicateNotificationDialog: React.FC<DuplicateNotificationDialogProps> = ({
  notification,
  handleClose,
  onDone,
  tags,
  calcUsersByTags,
}) => {
  const [title, setTitle] = useState(notification.title)
  const [body, setBody] = useState(notification.body)
  const [scheduled, setScheduled] = useState<Moment | null>(moment(notification.scheduled))

  const [tagsSelected, setTagsSelected] = useState<any[]>([])
  const [allTagsAreRequired, setAllTagsAreRequired] = useState(notification.allTagsAreRequired)
  const [usersByTags, setUsersByTags] = useState(0)
  const [visitsFilter, setVisitsFilter] = useState<string | null>(notification.visitsValue ?notification.visitsValue  : null )
  const [visitsOperator, setVisitsOperator] = useState<'==' | '>' | '<'>(notification.visitsOperator)
  const [startDate, setStartDate] = useState<Moment | null>(notification.dateFrom ? moment(notification.dateFrom) : null  )
  const [endDate, setEndDate] = useState<Moment | null>(notification.dateTo ? moment(notification.dateTo) : null )
  const [rewardId, setRewardId] = useState<string>(notification.rewardId ? notification.rewardId : '')
  const [invertedLogic, setInvertedLogic] = useState<boolean>(notification.rewardInverted ? notification.rewardInverted : false)
   const [userCount, setUserCount] = useState<number | null>(null)

  const data = { title, body,visitsFilter }
  const requiredFields = ['title', 'body', 'scheduled']
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules, requiredFields)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

  // 🔹 Esta función ahora es EXACTAMENTE la misma que la que se usa para `userCount`
  const updateUsersByTags = async (selectedTags: any[], allTags: boolean) => {
    const selectedTagsAsTags = selectedTags
      .map(tag => tags.find(t => t.id === tag.value))
      .filter(Boolean) as Tag[]

    const totalUsers = await calcUsersByTags(selectedTagsAsTags, allTags)
    setUsersByTags(totalUsers)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const newValue = !allTagsAreRequired
    setAllTagsAreRequired(newValue)
    updateUsersByTags(tagsSelected, newValue) // 🔹 Ahora usa la misma función de userCount
  }

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
    updateUsersByTags(e, allTagsAreRequired) // 🔹 Ahora usa la misma función de userCount
  }

  const [createNotificationApi, isLoading] = useApiCall<
    CreateNotificationPayload,
    CreateNotificationResponse
  >(createNotification)

  const handleDateChange = (newDate: Moment | null) => {
    setScheduled(newDate)
  }

  const [requestData, isRequestingData] = useApiCall<DataParams, DataResponse>(fetchPushUserCount)

  const handleSubmit = async () => {
    if (!scheduled) return
    const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)
    const data = {
      title,
      body,
      scheduled: scheduled.toISOString(),
      tagIds: tagsSelectedId,
      allTagsAreRequired,
      ...(visitsFilter !== null ? { visitsValue: parseInt(visitsFilter) } : {}),
      visitsOperator,
      ...(startDate?.toISOString() ? { dateFrom: startDate?.toISOString() } : {}),
      ...(endDate?.toISOString() ? { dateTo: endDate?.toISOString() } : {}),
      ...(rewardId ? { rewardId } : {}),
      ...(invertedLogic ? { rewardInverted: invertedLogic } : {}),
    }
    try {
      const { notification: newNotification } = await createNotificationApi(data)
      onDone(newNotification)
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  useEffect(() => {
    if (notification.tags) {
      const initialTagsSelected = notification.tags.map(el => ({ label: el.title, value: el.id }))
      setTagsSelected(initialTagsSelected)
      updateUsersByTags(initialTagsSelected, allTagsAreRequired) // 🔹 Ahora usa la misma función de userCount
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

   const handleFetchPushUserCount = async () => {
      try {
        const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)
        
        const data = await requestData({
          visitsValue:
            visitsFilter !== null && visitsFilter.length ? parseInt(visitsFilter) : undefined,
          visitsOperator,
          dateFrom: startDate?.toISOString() || undefined,
          dateTo: endDate?.toISOString() || undefined,
          rewardId: rewardId ? parseInt(rewardId) : undefined,
          rewardInverted: invertedLogic,
          tagIds: tagsSelectedId,
          allTagsAreRequired,
        })
  
        setUserCount(data.count)
      } catch (err) {
        console.error(err)
        snackbar.show('No se pudo calcular la cantidad de usuarios. Intente de nuevo.')
      }
    }
  
    useEffect(() => {
      handleFetchPushUserCount()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      visitsFilter,
      visitsOperator,
      startDate,
      endDate,
      rewardId,
      invertedLogic,
      tagsSelected,
      allTagsAreRequired,
    ])

  const classes = useStyles()
  const theme = useTheme()

  return (
    <Dialog
      title="Duplicar notificación"
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Duplicar notificación"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      //overflow
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            required
            onChange={e => setTitle(e.target.value)}
            value={title}
            error={errors.title.hasError}
            helperText={errors.title.message}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Mensaje"
            required
            onChange={e => setBody(e.target.value)}
            value={body}
            error={errors.body.hasError}
            helperText={errors.body.message}
          />
        </Grid>
        <Grid item xs={12}>
          <DateTimePicker
            value={scheduled}
            onChange={handleDateChange}
            autoOk
            disablePast
            format={STRINGS.DATE_TIME_FORMAT}
            initialFocusedDate={new Date()}
            disabled={isLoading}
            label="Programado"
            required
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>Tag</InputLabel>
          <MultiSelect
            options={tagsForSelect}
            value={tagsSelected}
            onChange={handleSetTags}
            labelledBy="Select"
            hasSelectAll={false}
            valueRenderer={() => null}
            className={classes.multiSelectChild}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox onChange={handleAllTagsAreRequiredChange} checked={allTagsAreRequired} />
            }
            label="Sólo usuarios con todos los tags seleccionados"
          />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.dialogSubTitle}>
            Rango de visitantes
          </Typography>
          <Grid item xs={12}>
            <VisitsFilter
              visitsFilter={visitsFilter}
              setVisitsFilter={setVisitsFilter}
              visitsOperator={visitsOperator}
              setVisitsOperator={setVisitsOperator}
              error={errors.visitsFilter.hasError}
              helperText={errors.visitsFilter.message}
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangeFilter
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              dateFormat={STRINGS.DATE_FORMAT}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.dialogSubTitle}>
            Premio
          </Typography>
          <RewardFilter
            rewardId={rewardId}
            setRewardId={setRewardId}
            invertedLogic={invertedLogic}
            setInvertedLogic={setInvertedLogic}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            <div className={classes.tootlTipUsers}>
              Esta acción impactará a {userCount} usuarios
            </div>
          </InputLabel>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export { DuplicateNotificationDialog }
