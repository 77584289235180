import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { DatePicker, DateTimePicker } from '@material-ui/pickers'
import Divider from '@material-ui/core/Divider'
import { InputLabel, LinearProgress, Chip, Typography, useTheme } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { MultiSelect } from 'react-multi-select-component'
import InputAdornment from '@material-ui/core/InputAdornment'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'
import moment, { Moment } from 'moment'

import { Dialog, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS, STRINGS } from 'consts'
import { Tag } from 'features/Main/Tags/types'

import { useStyles } from './styles'
import { fetchPushUserCount, updateNotification } from '../api'
import {
  UpdateNotificationData,
  Notification,
  UpdateNotificationResponse,
  DataResponse,
  DataParams,
} from '../types'
import VisitsFilter from './filters/VisitsFilter'
import DateRangeFilter from './filters/DateRangeFilter'
import AwardFilter from './filters/RewardFilter'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 50,
      message: 'No puede superar los 50 caracteres',
    },
  ],
  body: [
    {
      validate: (body: string) => body.length <= 192,
      message: 'No puede superar los 192 caracteres',
    },
  ],
  visitsFilter: [
    {
      validate: (visitsFilter: string | null) =>
        visitsFilter == null ? true : parseInt(visitsFilter) <= 1000,
      message: 'Máximo permitido: 1000',
    },
  ],
}

interface UpdateNotificationDialogProps {
  notification: Notification
  handleClose: () => void
  onDone: (newNotification: Notification) => void
  tags: Tag[]
  calcUsersByTags: (tagsSelected: any[], allTagsAreRequired?: boolean) => Promise<number>
}

const UpdateNotificationDialog: React.FC<UpdateNotificationDialogProps> = ({
  notification,
  handleClose,
  onDone,
  tags,
  calcUsersByTags,
}) => {
  const [title, setTitle] = useState(notification.title)
  const [body, setBody] = useState(notification.body)
  const [scheduled, setScheduled] = useState<Moment | null>(moment(notification.scheduled))
  const [tagsSelected, setTagsSelected] = useState<any[]>([])
  const [usersByTags, setUsersByTags] = useState(0)
  const [allTagsAreRequired, setAllTagsAreRequired] = useState(notification.allTagsAreRequired)
  const [visitsFilter, setVisitsFilter] = useState<string | null>(
    notification.visitsValue == null ? null : notification.visitsValue,
  )
  const [visitsOperator, setVisitsOperator] = useState<'==' | '>' | '<'>(
    notification.visitsOperator,
  )
  const [startDate, setStartDate] = useState<Moment | null>(
    notification.dateFrom ? moment(notification.dateFrom) : null,
  )
  const [endDate, setEndDate] = useState<Moment | null>(
    notification.dateTo ? moment(notification.dateTo) : null,
  )
  const [rewardId, setRewardId] = useState<string>(notification.rewardId ?? '')
  const [invertedLogic, setInvertedLogic] = useState<boolean>(notification.rewardInverted)
  const [userCount, setUserCount] = useState<number | null>(null)

  const requiredFields = ['title', 'body']
  const data = { title, body, visitsFilter }
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules, requiredFields)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors || notification.sent

  const classes = useStyles()
  const theme = useTheme()

  const [updateNotificationApi, isLoading] = useApiCall<
    UpdateNotificationData,
    UpdateNotificationResponse
  >(updateNotification)
  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

  useEffect(() => {
    if (!notification.tags) return

    const localTagsSelected = notification.tags.map(el => ({ label: el.title, value: el.id }))
    setTagsSelected(localTagsSelected)

    const getUsersByTags = async () => {
      return await calcUsersByTags(localTagsSelected, allTagsAreRequired)
    }
    getUsersByTags().then(el => {
      setUsersByTags(el)
    })
  }, [allTagsAreRequired, notification.tags])

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => setUsersByTags(el))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsSelected])

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    await setAllTagsAreRequired(!allTagsAreRequired)
    setUsersByTags(total)
  }

  const handleDateChange = (newDate: Moment | null) => {
    setScheduled(newDate)
  }

  const handleSubmit = async () => {
    if (!scheduled) return
    const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)
    const data = {
      title,
      body,
      scheduled: scheduled.toISOString(),
      tagIds: tagsSelectedId,
      allTagsAreRequired,
      visitsValue: visitsFilter,
      visitsOperator,
      dateFrom: startDate?.toISOString() ?? null,
      dateTo: endDate?.toISOString() || null,
      rewardId: rewardId || null,
      rewardInverted: invertedLogic,
    }
    try {
      const { notification: updatedNotification } = await updateNotificationApi({
        id: notification.id,
        payload: data,
      })
      onDone(updatedNotification)
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  const [requestData, isRequestingData] = useApiCall<DataParams, DataResponse>(fetchPushUserCount)
  const handleFetchPushUserCount = async () => {
    try {
      const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)

      const data = await requestData({
        visitsValue:
          visitsFilter !== null && visitsFilter.length ? parseInt(visitsFilter) : undefined,
        visitsOperator,
        dateFrom: startDate?.toISOString() || undefined,
        dateTo: endDate?.toISOString() || undefined,
        rewardId: rewardId ? parseInt(rewardId) : undefined,
        rewardInverted: invertedLogic,
        tagIds: tagsSelectedId,
        allTagsAreRequired,
      })

      setUserCount(data.count)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo calcular la cantidad de usuarios. Intente de nuevo.')
    }
  }

  useEffect(() => {
    handleFetchPushUserCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    visitsFilter,
    visitsOperator,
    startDate,
    endDate,
    rewardId,
    invertedLogic,
    tagsSelected,
    allTagsAreRequired,
  ])

  return (
    <Dialog
      title={
        notification!.sent
          ? `No es posible actualizar una notificación ya enviada`
          : `Editar notificación`
      }
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Editar notificación"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      // overflow
      style={{ minHeight: '40%' }}
    >
      {!notification!.sent && (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Título"
              required
              onChange={e => setTitle(e.target.value)}
              value={title}
              error={errors.title.hasError}
              helperText={errors.title.message}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Mensaje"
              required
              onChange={e => setBody(e.target.value)}
              value={body}
              error={errors.body.hasError}
              helperText={errors.body.message}
            />
          </Grid>
          <Grid item xs={12}>
            <DateTimePicker
              value={scheduled}
              onChange={handleDateChange}
              autoOk
              disablePast
              format={STRINGS.DATE_TIME_FORMAT}
              initialFocusedDate={new Date()}
              disabled={isLoading}
              label="Programado"
              required
              TextFieldComponent={props => (
                <TextField
                  {...props}
                  fullWidth
                  InputProps={{
                    ...props.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarTodayIcon style={{ padding: '2px' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Tag</InputLabel>

            {/* Solo se muestra si hay al menos un tag seleccionado */}
            {tagsSelected.length > 0 && (
              <div
                style={{
                  minHeight: '40px',
                  maxHeight: '120px',
                  overflowY: 'auto',
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '8px',
                  border: '1px solid #ccc',
                  borderRadius: '4px',
                  padding: '8px',
                }}
              >
                {tagsSelected.map(tag => (
                  <Chip
                    key={tag.value}
                    label={tag.label}
                    onDelete={() => handleSetTags(tagsSelected.filter(t => t.value !== tag.value))}
                    color="primary"
                  />
                ))}
              </div>
            )}

            <MultiSelect
              options={tagsForSelect}
              value={tagsSelected}
              onChange={handleSetTags}
              labelledBy="Select"
              hasSelectAll={false}
              valueRenderer={() => null} // 🔹 Evita que los valores aparezcan dentro del input,
              className={classes.multiSelectChild}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox onChange={handleAllTagsAreRequiredChange} checked={allTagsAreRequired} />
              }
              label="Sólo usuarios con todos los tags seleccionados"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider variant="middle" />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" className={classes.dialogSubTitle}>
              Rango de visitantes
            </Typography>
            <VisitsFilter
              visitsFilter={visitsFilter}
              setVisitsFilter={setVisitsFilter}
              visitsOperator={visitsOperator}
              setVisitsOperator={setVisitsOperator}
              error={errors.visitsFilter.hasError}
              helperText={errors.visitsFilter.message}
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangeFilter
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              dateFormat={STRINGS.DATE_FORMAT}
            />
          </Grid>
          <Grid item xs={12}>
            <AwardFilter
              rewardId={rewardId}
              setRewardId={setRewardId}
              invertedLogic={invertedLogic}
              setInvertedLogic={setInvertedLogic}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>
              {isRequestingData ? (
                <LinearProgress />
              ) : (
                <div className={classes.tootlTipUsers}>
                  Esta acción impactará a {userCount} usuarios
                </div>
              )}
            </InputLabel>
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}

export { UpdateNotificationDialog }
