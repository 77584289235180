import { ConfigUtils } from 'utils/config'

// Definir el tipo para los colores
interface StoreColorTheme {
  PRIMARY_MAIN: string
  PRIMARY_CONTRAST: string
  SECONDARY_MAIN: string
  BACKGROUND: string
}

// Definir los temas de colores
const STORE_COLOR_THEMES: Record<string, StoreColorTheme> = {
  tea: {
    PRIMARY_MAIN: '#27352E',
    PRIMARY_CONTRAST: '#27352E',
    SECONDARY_MAIN: '#27352e',
    BACKGROUND: '#FFEDE0',
  },
  ge: {
    PRIMARY_MAIN: '#E78923',
    PRIMARY_CONTRAST: '#275E56',
    SECONDARY_MAIN: '#27352e',
    BACKGROUND: '#F5F6F8',
  },
  'le-pain-quotidien': {
    PRIMARY_MAIN: '#FDC400',
    PRIMARY_CONTRAST: '#FBF5EE',
    SECONDARY_MAIN: '#4E342E',
    BACKGROUND: '#FBF5EE',
  },
}

// Definir un tema por defecto (para evitar valores `undefined`)
const DEFAULT_STORE_THEME: StoreColorTheme = STORE_COLOR_THEMES.ge

// Obtener el tema según la aplicación o usar el tema por defecto
const STORE_COLORS: StoreColorTheme =
  STORE_COLOR_THEMES[process.env.REACT_APP_NAME?.toLowerCase() || 'ge'] || DEFAULT_STORE_THEME

export { STORE_COLORS }
