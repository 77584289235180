import CssBaseline from '@material-ui/core/CssBaseline'
import { createMuiTheme, MuiThemeProvider, Theme, makeStyles } from '@material-ui/core/styles'
import { Loader, LoaderOptions } from 'google-maps'
import React, { useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment'
import 'moment/locale/es'

import { tokenLogin } from 'api'
import { snackbar, SnackbarContainer } from 'components'
import { UserContext } from 'contexts'
import { Admin } from 'features/Main/Admins/types'
import { useApiCall } from 'hooks'
import { TokenLoginResponse } from 'types'
import { setAuthHeader, removeAuthHeader } from 'utils'
import { ERRORS } from 'consts'
import { ConfigUtils } from 'utils/config'

import { Login } from './Login'
import { Main } from './Main'
import { ConfirmRegistration } from './ConfirmRegistration'
import { Colors, GetStyleResponse, Style } from './Main/Styles/types'
import { getStyles } from './Main/Styles/api'

moment.locale('es')

const useGlobalStyles = makeStyles(theme => ({
  '@global': {
    '&::-webkit-scrollbar': {
      width: '6px',
      height: '6px',
    },
    '&::-webkit-scrollbar-track': {
      background: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#bbb',
      borderRadius: '3px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#aaa',
    },
  },
}))

const loadGoogleMaps = async () => {
  const options: LoaderOptions = { libraries: ['places'] }
  const loader = new Loader(process.env.REACT_APP_GOOGLE_MAPS_API_KEY, options)
  return loader.load()
}

const App: React.FC = () => {
  useGlobalStyles()
  const [isLoading, setIsLoading] = useState(true)
  const [isThemeLoading, setIsThemeLoading] = useState(true)
  const [admin, setAdmin] = useState<Admin | undefined>(() => {
    const localAdmin = localStorage.getItem('admin')
    if (!localAdmin) return undefined
    return JSON.parse(localAdmin)
  })

  const [tokenLoginApi] = useApiCall<void, TokenLoginResponse>(tokenLogin)

  useEffect(() => {
    const appName = process.env.REACT_APP_NAME?.toLowerCase() ?? '' // Si es undefined, lo convertimos en ''

    // Definir el título y favicon según la app
    const titles: Record<string, string> = {
      tea: 'TEA Backoffice',
      ge: 'GE Backoffice',
      'le-pain-quotidien': 'Le Pain Quotidien Backoffice',
    }

    const favicons: Record<string, string> = {
      tea: '/favicon-tea.ico',
      ge: '/favicon-ge.ico',
      'le-pain-quotidien': '/favicon-lpq.ico',
    }

    document.title = titles[appName] || 'Default Backoffice'

    let faviconLink = document.querySelector("link[rel*='icon']") as HTMLLinkElement
    if (!faviconLink) {
      faviconLink = document.createElement('link')
      faviconLink.rel = 'icon'
      document.head.appendChild(faviconLink)
    }

    faviconLink.href = favicons[appName] || '/favicon-ge.ico'
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('token')
    if (!token) {
      setIsLoading(false)
      return
    }

    const updateAdmin = async () => {
      try {
        setAuthHeader(token)
        const { user: updatedAdmin } = await tokenLoginApi()
        setAdmin(updatedAdmin)
        localStorage.setItem('admin', JSON.stringify(updatedAdmin))
      } catch (error) {
        removeAuthHeader()
        setAdmin(undefined)
        localStorage.clear()
      } finally {
        setIsLoading(false)
      }
    }
    updateAdmin()
  }, [tokenLoginApi])

  useEffect(() => {
    loadGoogleMaps()
  }, [])

  const [theme, setTheme] = useState<Theme>(createMuiTheme())
  const [styleApi] = useApiCall<void, GetStyleResponse>(getStyles)

  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const response = await styleApi()
        const { style } = response
        const colors: Colors = JSON.parse(style.colors.toString())
        const newTheme = createMuiTheme({
          palette: {
            primary: {
              main: colors.PRIMARY_MAIN, // COLORS.BASE
              contrastText: colors.PRIMARY_CONTRAST, // COLORS.LIGHT_GREY,
            },
            secondary: {
              main: colors.SECONDARY_MAIN,
            },
            background: {
              default: colors.BACKGROUND,
            },
          },
        })
        setTheme(newTheme)
        setIsThemeLoading(false)
      } catch (error) {
        console.error('Error fetching theme data:', error)
      }
    }

    fetchTheme()
  }, [styleApi])

  return (
    <>
      {!isThemeLoading ? (
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
            <>
              <CssBaseline />
              {!isLoading && (
                <UserContext.Provider value={{ admin, setAdmin }}>
                  <Router>
                    <Switch>
                      <Route path="/login" component={Login} />
                      <Route path="/confirm-registration" exact component={ConfirmRegistration} />
                      <Main />
                    </Switch>
                  </Router>
                  <SnackbarContainer
                    ref={ref => {
                      snackbar.containerInstance = ref
                    }}
                  />
                </UserContext.Provider>
              )}
            </>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      ) : null}
    </>
  )
}

export { App }
