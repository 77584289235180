import React, { useState } from 'react'
import EditIcon from '@material-ui/icons/Edit'

import { ERRORS, STRINGS } from 'consts'
import { useApi, useApiCall } from 'hooks'
import { snackbar } from 'components'

import { getNotifications } from './api'
import { DefaultTable } from '../components'
import { GetNotificationsResponse, Notification } from './types'
import { UpdateNotificationDialog } from './components'
import { Prizes } from '../Prizes'

const columns = [
  { title: 'ID', field: 'id', filtering: false },
  { title: 'Descripción', field: 'description' },
  { title: 'Título', field: 'title' },
  { title: 'Mensaje', field: 'body' },
  {
    title: 'Activa',
    render: (rowData: Notification) => rowData.active ? <>SI</> : <>NO</>,
    field: 'active',
    lookup: { true: 'SI', false: 'NO' }
  },
  { title: 'Premio', field: 'prize',render: (rowData: Notification) => rowData.prize.id + ' - ' + rowData.prize.title, },
  {
    title: 'Premio Activo',
    render: (rowData: Notification) => rowData.activePrize ? <>SI</> : <>NO</>,
    field: 'activePrize',
    lookup: { true: 'SI', false: 'NO' }
  }
]

const getNotificationsResponseGetter = (responseData: GetNotificationsResponse) => responseData?.notifications ?? []

const AutomaticNotifications: React.FC = () => {
  const [{ data: notifications, isLoading }, , setNotifications] = useApi<GetNotificationsResponse, Notification[]>(
    getNotifications,
    getNotificationsResponseGetter,
    { baseData: [], onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE) },
  )

  const [isUpdateDialogOpen, setIsUpdateDialogOpen] = useState(false)
  const [notificationToUpdate, setNotificationToUpdate] = useState<Notification | null>(null)

  const closeUpdateDialog = () => {
    setIsUpdateDialogOpen(false)
    setNotificationToUpdate(null)
  }

  const onUpdateDone = (newNotification: Notification) => {
    setNotifications(
      notifications.map(notification => {
        if (notification.id === newNotification.id) return newNotification
        return notification
      }),
    )
    snackbar.show('Notificación actualizada.')
    closeUpdateDialog()
  }

  const actions = [
    {
      icon: () => <EditIcon />,
      tooltip: 'Editar notificación',
      isFreeAction: false,
      onClick: (_: React.SyntheticEvent, notificacion: Notification | Notification[]) => {
        setNotificationToUpdate(notificacion as Notification)
        setIsUpdateDialogOpen(true)
      },
    }
  ]

  return (
    <>
      <DefaultTable
        title="Notificaciones Automaticas"
        columns={columns}
        data={notifications}
        isLoading={isLoading}
        actions={actions}
        options={{
          filtering: true,
          search: false
        }}
      />
      {isUpdateDialogOpen && notificationToUpdate && (
        <UpdateNotificationDialog
          notification={notificationToUpdate}
          handleClose={closeUpdateDialog}
          onDone={onUpdateDone}
        />
      )}
    </>
  )
}

export { AutomaticNotifications }
