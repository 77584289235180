import { Divider, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { COLORS } from 'styles'

const useStyles = makeStyles(theme => ({
  versionContainer: {
    margin: theme.spacing(1, 0),
    textAlign: 'center',
  },
  divider: {
    marginBottom: theme.spacing(1),
  },
  version: {
    color: COLORS.GRAY,
  },
}))

interface VersionProps {
  version: string | null
}

const Version: React.FC<VersionProps> = ({ version }) => {
  const classes = useStyles()

  return (
    <div className={classes.versionContainer}>
      <Divider className={classes.divider} />
      <Typography variant="caption" className={classes.version}>
        v{version}
      </Typography>
    </div>
  )
}

export { Version }
