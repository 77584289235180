/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable prefer-destructuring */
import React, { useContext, useEffect, useState } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Button from '@material-ui/core/Button'
import { useHistory } from 'react-router'

import { ReactComponent as LogoLepain } from 'assets/images/main-logo-le-pain.svg'
import { removeAuthHeader } from 'utils'
import { UserContext } from 'contexts'
import { ReactComponent as LogoTEA } from 'assets/images/main-logo-tea.svg'
import { ReactComponent as LogoGE } from 'assets/images/main-logo-ge.svg'
import { useApiCall } from 'hooks'
import { GetStyleResponse } from 'features/Main/Styles/types'
import { getStyles } from 'features/Main/Styles/api'
import { ConfigUtils } from 'utils/config'

import { useStyles } from './styles'

interface Props {
  isLoggedIn?: boolean
}

const NavBar: React.FC<Props> = ({ isLoggedIn }) => {
  const classes = useStyles()
  const { setAdmin } = useContext(UserContext)
  const history = useHistory()
  const onLogout = () => {
    localStorage.clear()
    removeAuthHeader()
    setAdmin(undefined)
    history.push('/login', { from: history.location })
  }

  const [styleApi] = useApiCall<void, GetStyleResponse>(getStyles)
  const [logoUrl, setLogoUrl] = useState('')
  const [isThemeLoading, setIsThemeLoading] = useState(true)

  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const response = await styleApi()
        const style = response.style
        setLogoUrl(style.logoUrl)
        setIsThemeLoading(false)
      } catch (error) {
        setIsThemeLoading(false)
        console.error('Error fetching theme data:', error)
      }
    }

    fetchTheme()
  }, [])

  const getNavbarLogo = () => {
    if (isThemeLoading) return null
    if (logoUrl) return <img src={logoUrl} alt="Logo" className={classes.logo} />

    if (ConfigUtils.isApp('tea')) return <LogoTEA height={50} width={200} />
    if (ConfigUtils.isApp('ge')) return <LogoGE height={50} width={200} />
    if (ConfigUtils.isApp('le-pain-quotidien')) return <LogoLepain height={15} width={224} />

    return <LogoGE height={50} width={200} />
  }

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        {getNavbarLogo()}
        {isLoggedIn && (
          <Button color="inherit" className={classes.logout} onClick={onLogout}>
            Cerrar sesión
          </Button>
        )}
      </Toolbar>
    </AppBar>
  )
}

NavBar.defaultProps = {
  isLoggedIn: false,
}

export { NavBar }
