import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogSubTitle: {
      fontWeight: 600,
      fontFamily: "'Montserrat', sans-serif",
      fontSize: '17px',
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
    fullWidth: {
      width: '100%',
    },
    tootlTipUsers: {
      backgroundColor: '#1265591A',
      border: '1px solid #275E56',
      padding: '16px',
      borderRadius: '10px',
      color: '#275E56',
    },
    multiSelectChild: {
      '& > div': {
        border: 'none !important',
        borderBottom: '1px solid #ccc !important',
        borderRadius: '0 !important',
        transition: 'border-bottom 0.3s ease-in-out', // Suaviza la animación del borde inferior
      },
      '&:focus-within > div': {
        border: 'none !important',
        borderBottom: '1px solid #ccc !important', // 🔹 Solo el borde inferior cambia en focus
      },
      '& .dropdown-heading': {
        padding: '0 !important', // 🔹 Asegura que no tenga relleno que genere un "borde" visual
      },
      '& .dropdown-container': {
        boxShadow: 'none !important',
        outline: 'none !important',
      },
    },
  }),
)
export { useStyles }
