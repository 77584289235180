import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { InputLabel, LinearProgress, TextField, Typography, Chip } from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import { createStyles, makeStyles, useTheme, Theme } from '@material-ui/core/styles'
import { Moment } from 'moment'
import { MultiSelect } from 'react-multi-select-component'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import InputAdornment from '@material-ui/core/InputAdornment'
import CalendarTodayIcon from '@material-ui/icons/CalendarToday'

import { Dialog, snackbar } from 'components'
import { useCheckFormErrors, useApiCall } from 'hooks'
import { ERRORS, STRINGS } from 'consts'
import { Tag } from 'features/Main/Tags/types'

import { createNotification, fetchPushUserCount } from '../api'
import {
  CreateNotificationPayload,
  CreateNotificationResponse,
  DataParams,
  DataResponse,
  Notification,
} from '../types'
import VisitsFilter from './filters/VisitsFilter'
import DateRangeFilter from './filters/DateRangeFilter'
import RewardFilter from './filters/RewardFilter'
import { useStyles } from './styles'

const rules = {
  title: [
    {
      validate: (title: string) => title.length <= 50,
      message: 'No puede superar los 50 caracteres',
    },
  ],
  body: [
    {
      validate: (body: string) => body.length <= 192,
      message: 'No puede superar los 192 caracteres',
    },
  ],
  scheduled: [
    {
      validate: (scheduled: Moment | null) => scheduled !== null,
      message: 'Este campo es requerido',
    },
  ],
  visitsFilter: [
    {
      validate: (visitsFilter: string | null) =>
        visitsFilter == null ? true : parseInt(visitsFilter) <= 1000,
      message: 'Máximo permitido: 1000',
    },
  ],
}

interface CreateNotificationsDialogProps {
  handleClose: () => void
  onDone: (newNotification: Notification) => void
  tags: Tag[]
  calcUsersByTags: (tagsSelected: Tag[], allTagsAreRequired?: boolean) => Promise<number>
}

const CreateNotificationDialog: React.FC<CreateNotificationsDialogProps> = ({
  handleClose,
  onDone,
  tags,
  calcUsersByTags,
}) => {
  const [title, setTitle] = useState('')
  const [body, setBody] = useState('')
  const [scheduled, setScheduled] = useState<Moment | null>(null)
  const [tagsSelected, setTagsSelected] = useState<any[]>([])
  const [usersByTags, setUsersByTags] = useState(0)
  const [visitsFilter, setVisitsFilter] = useState<string | null>(null)
  const [visitsOperator, setVisitsOperator] = useState<'==' | '>' | '<'>('==')
  const [startDate, setStartDate] = useState<Moment | null>(null)
  const [endDate, setEndDate] = useState<Moment | null>(null)
  const [rewardId, setRewardId] = useState<string>('')
  const [invertedLogic, setInvertedLogic] = useState<boolean>(false)
  const [userCount, setUserCount] = useState<number | null>(null)

  const data = { title, body, scheduled, visitsFilter }
  const requiredFields = ['title', 'body', 'scheduled']
  const { isAnyFieldEmpty, hasErrors, errors } = useCheckFormErrors(data, rules, requiredFields)
  const isSubmitDisabled = isAnyFieldEmpty || hasErrors

  const classes = useStyles()
  const theme = useTheme()
  const [personName, setPersonName] = React.useState<string[]>([])

  const [allTagsAreRequired, setAllTagsAreRequired] = useState(false)

  const tagsForSelect = tags.map(el => ({ label: el.title, value: el.id }))

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setPersonName(event.target.value as string[])
  }

  const [createNotificationApi, isLoading] = useApiCall<
    CreateNotificationPayload,
    CreateNotificationResponse
  >(createNotification)

  const handleSetTags = async (e: any[]) => {
    setTagsSelected(e)
  }

  useEffect(() => {
    calcUsersByTags(tagsSelected, allTagsAreRequired).then(el => setUsersByTags(el))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tagsSelected])

  const handleSubmit = async () => {
    if (!scheduled) return
    const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)
    const data = {
      title,
      body,
      scheduled: scheduled.toISOString(),
      tagIds: tagsSelectedId,
      allTagsAreRequired,
      ...(visitsFilter !== null ? { visitsValue: parseInt(visitsFilter) } : {}),
      visitsOperator,
      ...(startDate?.toISOString() ? { dateFrom: startDate?.toISOString() } : {}),
      ...(endDate?.toISOString() ? { dateTo: endDate?.toISOString() } : {}),
      ...(rewardId ? { rewardId } : {}),
      ...(invertedLogic ? { rewardInverted: invertedLogic } : {}),
    }
    try {
      const { notification: newNotification } = await createNotificationApi(data)
      onDone(newNotification)
    } catch (err) {
      console.error(err)
      snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE)
    }
  }

  const handleDateChange = (newDate: Moment | null) => {
    setScheduled(newDate)
  }

  const handleAllTagsAreRequiredChange: React.ChangeEventHandler<HTMLInputElement> = async e => {
    const total = await calcUsersByTags(tagsSelected, !allTagsAreRequired)
    await setAllTagsAreRequired(!allTagsAreRequired)
    setUsersByTags(total)
  }

  const [requestData, isRequestingData] = useApiCall<DataParams, DataResponse>(fetchPushUserCount)

  const handleFetchPushUserCount = async () => {
    try {
      const tagsSelectedId = tagsSelected.map((tag: { value: number }) => tag.value)

      const data = await requestData({
        visitsValue:
          visitsFilter !== null && visitsFilter.trim().length ? parseInt(visitsFilter) : undefined,
        visitsOperator,
        dateFrom: startDate?.toISOString() || undefined,
        dateTo: endDate?.toISOString() || undefined,
        rewardId: rewardId ? parseInt(rewardId) : undefined,
        rewardInverted: invertedLogic,
        tagIds: tagsSelectedId,
        allTagsAreRequired,
      })

      setUserCount(data.count)
    } catch (err) {
      console.error(err)
      snackbar.show('No se pudo calcular la cantidad de usuarios. Intente de nuevo.')
    }
  }

  useEffect(() => {
    handleFetchPushUserCount()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    visitsFilter,
    visitsOperator,
    startDate,
    endDate,
    rewardId,
    invertedLogic,
    tagsSelected,
    allTagsAreRequired,
  ])

  return (
    <Dialog
      title="Nueva notificación"
      subtitle="Descripción de notificación"
      isOpen
      onCancel={handleClose}
      showActions
      okButtonText="Crear"
      okButtonProps={{ disabled: isSubmitDisabled }}
      onAccept={handleSubmit}
      isLoading={isLoading}
      contentStyle={{ minWidth: 500 }}
      //   overflow
      style={{ minHeight: '40%' }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Título"
            required
            onChange={e => setTitle(e.target.value)}
            value={title}
            error={errors.title.hasError}
            helperText={errors.title.message}
            style={{ marginTop: 10 }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Mensaje"
            required
            onChange={e => setBody(e.target.value)}
            value={body}
            multiline
            error={errors.body.hasError}
            helperText={errors.body.message}
          />
        </Grid>
        <Grid item xs={12}>
          <DateTimePicker
            value={scheduled}
            onChange={handleDateChange}
            autoOk
            disablePast
            format={STRINGS.DATE_TIME_FORMAT}
            initialFocusedDate={new Date()}
            disabled={isLoading}
            label="Programado"
            required
            TextFieldComponent={props => (
              <TextField
                {...props}
                fullWidth
                InputProps={{
                  ...props.InputProps,
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarTodayIcon style={{ padding: '2px' }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <InputLabel>Tag</InputLabel>

          {/* Solo se muestra si hay al menos un tag seleccionado */}
          {tagsSelected.length > 0 && (
            <div
              style={{
                minHeight: '40px',
                maxHeight: '120px',
                overflowY: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                gap: '8px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                padding: '8px',
              }}
            >
              {tagsSelected.map(tag => (
                <Chip
                  key={tag.value}
                  label={tag.label}
                  onDelete={() => handleSetTags(tagsSelected.filter(t => t.value !== tag.value))}
                  color="primary"
                />
              ))}
            </div>
          )}

          <MultiSelect
            options={tagsForSelect}
            value={tagsSelected}
            onChange={handleSetTags}
            labelledBy="Select"
            hasSelectAll={false}
            valueRenderer={() => null} // 🔹 Evita que los valores aparezcan dentro del input,
            className={classes.multiSelectChild}
          />
        </Grid>

        <Grid item xs={11}>
          <FormControlLabel
            control={
              <Checkbox onChange={handleAllTagsAreRequiredChange} checked={allTagsAreRequired} />
            }
            label="Sólo usuarios con todos los tags seleccionados"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.dialogSubTitle}>
            Rango de visitantes
          </Typography>
          <Grid item xs={12}>
            <VisitsFilter
              visitsFilter={visitsFilter}
              setVisitsFilter={setVisitsFilter}
              visitsOperator={visitsOperator}
              setVisitsOperator={setVisitsOperator}
              error={errors.visitsFilter.hasError}
              helperText={errors.visitsFilter.message}
            />
          </Grid>
          <Grid item xs={12}>
            <DateRangeFilter
              startDate={startDate}
              setStartDate={setStartDate}
              endDate={endDate}
              setEndDate={setEndDate}
              dateFormat={STRINGS.DATE_FORMAT}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1" className={classes.dialogSubTitle}>
            Premio
          </Typography>
          <RewardFilter
            rewardId={rewardId}
            setRewardId={setRewardId}
            invertedLogic={invertedLogic}
            setInvertedLogic={setInvertedLogic}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel>
            {isRequestingData ? (
              <LinearProgress />
            ) : (
              <div className={classes.tootlTipUsers}>
                Esta acción impactará a {userCount} usuarios
              </div>
            )}
          </InputLabel>
        </Grid>
      </Grid>
    </Dialog>
  )
}

const names = ['TAG-1', 'TAG-2', 'TAG-3', 'TAG-4', 'TAG-5']

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

export { CreateNotificationDialog }
