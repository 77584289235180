import React from 'react'
import MaterialTable, { MaterialTableProps, Query } from 'material-table'
import { Tooltip, makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  tooltip: {
    fontSize: '16px',
  },
})

const buildPaginationQuery = <T extends object>(query: Query<T>) => {
  const { page, pageSize, search, orderBy, orderDirection } = query

  const pageQuery = `page=${page ?? 0}`
  const pageSizeQuery = `&pageSize=${pageSize ?? 10}`
  const searchQuery = search ? `&search=${search}` : ''
  const orderByQuery = orderBy ? `&orderBy=${String(orderBy.field)}` : ''
  const orderDirectionQuery = orderDirection ? `&orderDirection=${orderDirection}` : ''

  return `${pageQuery}${pageSizeQuery}${searchQuery}${orderByQuery}${orderDirectionQuery}`
}

const DefaultTable = <T extends object>(props: MaterialTableProps<T>) => {
  const classes = useStyles()
  const { options, columns, ...restProps } = props

  const enhancedColumns = columns.map((column, index) => ({
    ...column,
    cellStyle: {
      ...column.cellStyle,
      maxWidth: '200px',
      whiteSpace: 'nowrap' as const,
      overflow: 'hidden' as const,
      textOverflow: 'ellipsis' as const,
      paddingLeft: index === 0 ? '24px' : undefined,
    },
    headerStyle: {
      ...column.headerStyle,
      paddingLeft: index === 0 ? '24px' : undefined,
    },
    render:
      column.render ??
      ((rowData: T) => {
        const cellValue = rowData[column.field as keyof T]
        return cellValue ? (
          <Tooltip
            title={String(cellValue)}
            placement="bottom-start"
            classes={{ tooltip: classes.tooltip }}
          >
            <div
              style={{
                whiteSpace: 'nowrap' as const,
                overflow: 'hidden' as const,
                textOverflow: 'ellipsis' as const,
              }}
            >
              {cellValue}
            </div>
          </Tooltip>
        ) : null
      }),
  }))

  return (
    <div style={{ maxWidth: '100%' }}>
      <MaterialTable
        localization={{
          pagination: {
            labelDisplayedRows: '{from}-{to} de {count}',
            labelRowsSelect: 'filas',
          },
          toolbar: {
            nRowsSelected: '{0} filas(s) seleccionadas',
            searchTooltip: 'Buscar',
            searchPlaceholder: 'Buscar',
          },
          header: {
            actions: 'Acciones',
          },
          body: {
            emptyDataSourceMessage: 'No hay nada todavía.',
            filterRow: {
              filterTooltip: 'Filtrar',
            },
          },
        }}
        options={{
          pageSize: 10,
          pageSizeOptions: [5, 10, 20, 50, 100],
          actionsColumnIndex: columns.length,
          searchFieldStyle: {
            marginRight: '8px',
          },
          headerStyle: {
            padding: '16px',
          },
          rowStyle: (data, index) => ({
            backgroundColor: index % 2 === 0 ? '#FAFAFA' : '#FFF',
          }),
          actionsCellStyle: {
            paddingRight: '8px',
          },
          ...options,
        }}
        columns={enhancedColumns}
        {...restProps}
      />
    </div>
  )
}

export { DefaultTable, buildPaginationQuery }
