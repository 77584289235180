import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  overflow: {
    overflow: 'visible',
  },
  dialogContent: {
    padding: '15px 30px',
  },
  title: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 700,
    fontSize: '0.9em',
    color: '#212121',
    lineHeight: '1.4em',
  },
  subtitle: {
    fontFamily: "'Montserrat', sans-serif",
    fontWeight: 700,
    fontSize: '17px',
    color: '#212121',
    lineHeight: '1em',
  },
}))

export { useStyles }
