import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
  },
  iconUploadContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  multiselectTitle: {
    marginBottom: '4px'
  },
  iconPreview: { maxWidth: 200, maxHeight: '100%' },
  tootlTipUsers: {
    backgroundColor: '#1265591A',
    border: '1px solid #275E56',
    padding: '16px',
    borderRadius: '10px',
    color: '#275E56',
  },
})

export { useStyles }
