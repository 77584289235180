import { ConfigUtils } from 'utils/config'

// Definir el tipo de estructura para los colores
interface ColorTheme {
  BASE: string
  PRIMARY_BACKGROUND: string
  ACCENT: string
  SECONDARY_MAIN: string
  WHITE: string
  LIGHT_GREY: string
  GRAY: string
  DARK_GREY: string
}

// Definir los temas de colores
const COLOR_THEMES: Record<string, ColorTheme> = {
  tea: {
    BASE: '#27352e',
    PRIMARY_BACKGROUND: 'rgb(245, 246, 248)',
    ACCENT: '#efefef',
    SECONDARY_MAIN: '#27352e',
    WHITE: '#fff',
    LIGHT_GREY: '#efefef',
    GRAY: 'rgba(0, 0, 0, 0.54)',
    DARK_GREY: '#2E2E2E',
  },
  ge: {
    BASE: '#E78923',
    PRIMARY_BACKGROUND: 'rgb(245, 246, 248)',
    ACCENT: '#275E56',
    SECONDARY_MAIN: '#275E56',
    WHITE: '#fff',
    LIGHT_GREY: '#efefef',
    GRAY: 'rgba(0, 0, 0, 0.54)',
    DARK_GREY: '#2E2E2E',
  },
  'le-pain-quotidien': {
    BASE: '#FDC400',
    PRIMARY_BACKGROUND: '#FBF5EE',
    ACCENT: '#FBF5EE',
    SECONDARY_MAIN: '#FDC400',
    WHITE: '#fff',
    LIGHT_GREY: '#F2E6D9',
    GRAY: 'rgba(0, 0, 0, 0.54)',
    DARK_GREY: '#4E342E',
  },
}

// Definir un tema por defecto
const DEFAULT_THEME: ColorTheme = COLOR_THEMES.ge

// Obtener el tema según la aplicación o usar el tema por defecto
const COLORS: ColorTheme =
  COLOR_THEMES[process.env.REACT_APP_NAME?.toLowerCase() || 'ge'] || DEFAULT_THEME

export { COLORS }
