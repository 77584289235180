const isApp = (...appNames: string[]): boolean => {
  const app = process.env.REACT_APP_NAME?.toLowerCase()
  return app ? appNames.some(name => name.toLowerCase() === app) : false
}

// Ejemplo de funciones específicas, si las necesitas
const isTeaConnection = (): boolean => isApp('tea')
const isGEConnection = (): boolean => isApp('ge')
const isLepainConnection = (): boolean => isApp('le-pain-quotidien')

export const ConfigUtils = {
  isApp,
  isTeaConnection,
  isGEConnection,
  isLepainConnection,
}
